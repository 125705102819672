import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from "moment";
@Injectable()
export class PrintService {

  isPrinting = false;

  constructor(private router: Router) { }

  printDocument(documentName: string, documentData: any) {
    this.isPrinting = true;
    if(documentName == 'Swap Ticket') {
      this.router.navigate(['print', 'invoice'], {queryParams: {
        ticket_id: documentData.ticket_id,
        employee: documentData.employee,
        original_device_description: documentData.original_device_description,
        swap_device_description: documentData.swap_device_description,
        note: documentData.note
      }}
      );
    }
    if(documentName == 'Assigment') {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['print', 'assigment'], {queryParams : {
          logo: documentData?.device?.company?.logo,
          first_name: documentData?.employee?.employee_name.first_name,
          last_name: documentData?.employee?.employee_name.last_name,
          device: documentData?.device?.device_variant ?
          documentData?.device?.device_variant?.description :
          documentData?.device?.denormalized_device_variant?.name,
          serial_number: documentData?.device?.serial_number,
          imei: documentData?.device?.imei,
          company: documentData?.device?.company?.name,
          comment: documentData?.employee?.comment,
          branch: documentData?.device?.branch?.name,
          branch_address: documentData?.device?.branch?.address_line_1,
          company_id: documentData?.device?.company_id,
          date: moment().format('LL').valueOf()
        }})
      );
     return window.open(url, '_blank');
    }
    if(documentName == 'ServiceCase') {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['print', 'service-case'], {queryParams : {
          device: documentData.device,
          serial_number: documentData.serial_number,
          insurance: documentData.insurance,
          company: documentData.company,
          employee: documentData.employee,
          reporter: documentData.reporter,
          address: documentData.address,
          contact_email: documentData.contact_email,
          description: documentData.description,
          note: documentData.note,
          reference_number: documentData.reference_number,
          city: documentData?.city,
          zip: documentData?.zip
        }})
      );
     return window.open(url, '_blank');
    }
    if (documentName === 'qrCode') {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['print', 'qr-code'], {queryParams : {
          action: documentData.action,
          address: documentData.address,
          pin: documentData?.pin
        }})
      );
      window.open(url, '_blank');
    }
    else {
      this.router.navigate(['print', documentName],
        {queryParams:
         {
           ticket_id: documentData.ticket_id,
           employee: documentData.employee,
           serial_number: documentData.serial_number,
           pool_device_serial: documentData.pool_device_serial,
           device_name: documentData.device_name,
           pool_device_name: documentData.pool_device_name,
           note: documentData.note}}
       );
    }
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      localStorage.removeItem('qrCode');
      this.router.navigate(['/devices/list']);
    }, 2000);
  }
}
